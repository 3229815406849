import React from 'react'
import styles from "../styles/logosStyles.module.css"
const Hp = () => {
    return (
        <svg
            //  width="48" height="48" 
            className={styles.svgStyles}
            viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_21_255)">
                <g clip-path="url(#clip1_21_255)">
                    <path d="M52.983 -5H-4.98301C-4.99239 -5 -5 -4.99239 -5 -4.98301V52.983C-5 52.9924 -4.99239 53 -4.98301 53H52.983C52.9924 53 53 52.9924 53 52.983V-4.98301C53 -4.99239 52.9924 -5 52.983 -5Z" fill="white" />
                    <path d="M24.0009 2.24961C23.6724 2.24961 23.3439 2.24961 23.0267 2.27227L18.5747 14.5066H22.4489C24.7486 14.5066 25.9946 16.2738 25.2017 18.4488L19.7189 33.5152H15.1083L20.9989 17.3613H17.5439L11.6532 33.5152H7.05402L13.9642 14.484L18.1443 3.04258C13.5016 4.33963 9.42132 7.14226 6.5445 11.0102C3.66768 14.8781 2.15731 19.5921 2.25066 24.4117C2.34402 29.2312 4.03582 33.8832 7.06027 37.6368C10.0847 41.3904 14.2704 44.0329 18.9599 45.1492L23.004 34.0477L30.1181 14.484H38.6142C40.9138 14.484 42.1599 16.2625 41.3669 18.4375L36.5525 31.6801C36.3326 32.203 35.969 32.653 35.5038 32.9778C35.0387 33.3026 34.491 33.4889 33.9243 33.5152H27.8071L23.3665 45.727L24.0122 45.7383C29.7807 45.7383 35.3129 43.4468 39.3918 39.3679C43.4707 35.2889 45.7622 29.7567 45.7622 23.9883C45.7622 18.2198 43.4707 12.6876 39.3918 8.60871C35.3129 4.52979 29.7807 2.23828 24.0122 2.23828L24.0009 2.24961ZM33.6978 17.35L28.8493 30.6492H32.3157L37.1642 17.35H33.6978Z" fill="#0096D6" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_21_255">
                    <rect width="48" height="48" fill="white" />
                </clipPath>
                <clipPath id="clip1_21_255">
                    <rect width="58" height="58" fill="white" transform="translate(-5 -5)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Hp