import React from 'react'
import styles from "../styles/logosStyles.module.css"

const Udemy = () => {
    return (
        <svg
            //  width="48" height="48" 
            className={styles.svgStyles}
            viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_21_311)">
                <path d="M16.9497 6.15229C18.9088 6.76027 19.5167 8.3309 19.5167 10.1211C19.4999 11.5397 19.331 12.9583 18.9932 14.3432C18.267 17.5013 17.3719 20.6257 16.6288 23.7669C16.0208 26.3509 15.4635 28.9686 15.4466 31.637C15.4635 32.3632 15.5817 33.0894 15.7844 33.7818C16.1728 35.1667 17.3381 35.268 18.2163 34.4236C18.7737 33.8831 19.2803 33.3089 19.7363 32.6841C21.9318 29.7623 23.874 26.6549 25.529 23.3954C27.1334 20.271 30.7982 13.2117 31.0178 12.7895C32.1662 10.6784 35.3412 11.0331 36.3039 13.0934C36.8443 14.2081 37.1483 15.4409 37.199 16.6738C37.2834 19.3422 37.5367 26.79 37.6549 27.854C37.8745 29.9143 39.1918 30.573 40.9482 29.4246C42.0122 28.7322 42.9748 27.854 43.9375 27.0264C44.8325 26.2327 45.3054 26.3171 45.7445 27.4317C46.1329 28.4113 45.9641 29.3908 45.6432 30.3197C44.5623 33.4609 42.2148 35.1329 39.0905 35.8084C38.094 36.028 37.0639 36.0449 36.0505 35.876C33.8044 35.5044 32.3689 34.204 31.5075 32.1943C30.68 30.2521 30.4267 24.7296 30.2409 24.0203C29.9369 24.662 26.3397 32.0761 24.4482 34.8964C23.266 36.7035 21.8305 38.3417 20.1754 39.7434C18.5372 41.1114 16.6795 41.9558 14.5009 42.0065C11.8663 42.074 9.95788 40.875 8.64058 38.6457C7.79616 37.2102 7.4415 35.6058 7.32328 33.9507C7.1544 31.637 7.52594 29.3739 8.0326 27.1109C8.20148 26.3847 8.3197 25.6416 8.50547 24.7127C7.77927 25.0842 7.30639 25.422 6.76596 25.6078C6.1242 25.8273 5.43177 26.0469 4.75624 26.03C2.35808 25.9624 2.00342 23.311 2.00342 22.8719C2.81406 22.247 3.67537 21.6728 4.41847 20.9972C7.96504 17.7884 10.6334 13.9379 12.5587 9.58065C13.2005 8.14513 13.9942 6.91227 15.4129 6.15229C15.8857 5.94963 16.3079 5.93274 16.9497 6.15229Z" fill="#EB524F" />
            </g>
            <defs>
                <clipPath id="clip0_21_311">
                    <rect width="43.9512" height="36.04" fill="white" transform="translate(2 6)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Udemy