import React from 'react'
import styles from "../styles/logosStyles.module.css"

const Wordpress = () => {
    return (
        <svg
            //  width="48" height="48"
            className={styles.svgStyles}
            viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" fill="white" />
            <g clip-path="url(#clip0_240_380)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 23.9993C2 32.7075 7.06015 40.2331 14.3988 43.7986L3.90444 15.0464C2.68478 17.7813 2 20.8092 2 23.9993ZM38.8514 22.8896C38.8514 20.1698 37.8738 18.2874 37.0377 16.823C35.9226 15.0093 34.8762 13.4747 34.8762 11.6624C34.8762 9.63974 36.4093 7.75731 38.5709 7.75731C38.6685 7.75731 38.7607 7.76966 38.8556 7.77516C34.9422 4.18904 29.7266 2 23.9996 2C16.3131 2 9.55202 5.94362 5.61802 11.9154C6.13504 11.9319 6.6218 11.9416 7.03431 11.9416C9.33476 11.9416 12.8975 11.6624 12.8975 11.6624C14.0828 11.5923 14.223 13.3358 13.0377 13.4747C13.0377 13.4747 11.8456 13.615 10.52 13.6851L18.5311 37.5146L23.3464 23.0753L19.9198 13.6851C18.7346 13.615 17.6111 13.4747 17.6111 13.4747C16.4259 13.4046 16.5647 11.5923 17.7514 11.6624C17.7514 11.6624 21.3843 11.9416 23.5458 11.9416C25.8463 11.9416 29.409 11.6624 29.409 11.6624C30.5957 11.5923 30.7346 13.3358 29.5493 13.4747C29.5493 13.4747 28.3557 13.615 27.0316 13.6851L34.9821 37.3331L37.178 30.0014C38.1268 26.957 38.8514 24.7721 38.8514 22.8896ZM43.3049 13.4449C43.3998 14.1462 43.4534 14.897 43.4534 15.7069C43.4534 17.9386 43.0354 20.448 41.78 23.5872L35.0601 43.0152C41.6012 39.2022 46 32.1152 46 23.9997C46 20.1743 45.0223 16.5786 43.3049 13.4449ZM24.386 25.9238L17.7844 45.1043C19.7562 45.6846 21.8408 46.0008 23.9996 46.0008C26.5613 46.0008 29.0185 45.5581 31.3052 44.7537C31.2474 44.6588 31.1924 44.5598 31.1484 44.4512L24.386 25.9238Z" fill="#00759D" />
            </g>
            <defs>
                <clipPath id="clip0_240_380">
                    <rect width="44" height="44" fill="white" transform="translate(2 2)" />
                </clipPath>
            </defs>
        </svg>



    )
}

export default Wordpress