import React from 'react'
import styles from "../styles/logosStyles.module.css"
const GooglePay = () => {
    return (
        <svg
            className={styles.svgStyles}
            //  width="48" height="48" 
            viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16_8)">
                <path d="M42.5947 12.544C38.0775 9.93638 32.3012 11.4858 29.6908 16.0032L23.1105 27.4021C21.2065 30.6939 23.6577 31.844 26.391 33.4829L32.7227 37.1373C34.867 38.3742 37.6058 37.64 38.8425 35.4986L45.6072 23.7839C47.8796 19.8474 46.5312 14.8166 42.5947 12.544V12.544Z" fill="#EA4335" />
                <path d="M35.149 17.2621L28.8172 13.6076C25.3219 11.6672 23.3423 11.5388 21.9074 13.8197L12.5687 29.9929C9.96106 34.5073 11.5132 40.2809 16.0277 42.8828C19.9642 45.1553 24.9952 43.807 27.2678 39.8705L36.7852 23.3846C38.0275 21.2405 37.2933 18.4988 35.149 17.2621V17.2621Z" fill="#FBBC04" />
                <path d="M36.3678 8.94815L31.9204 6.37934C27.0012 3.54021 20.7111 5.22365 17.8718 10.1428L9.40413 24.8083C8.15345 26.9721 8.89606 29.7416 11.0598 30.9894L16.0404 33.865C18.4999 35.2862 21.6435 34.4429 23.0647 31.9834L32.7383 15.2295C34.7429 11.7594 39.1792 10.5701 42.6493 12.5746L36.3678 8.94798V8.94815Z" fill="#34A853" />
                <path d="M19.0747 14.3391L14.2672 11.5696C12.1231 10.3355 9.38428 11.0669 8.14755 13.2056L2.37931 23.1724C-0.459817 28.0777 1.22362 34.3536 6.14281 37.1847L9.80291 39.2925L14.2421 41.8497L16.1685 42.958C12.7485 40.6688 11.6624 36.0818 13.7534 32.4691L15.2472 29.8894L20.7163 20.439C21.9503 18.306 21.2161 15.573 19.0747 14.3389V14.3391Z" fill="#4285F4" />
            </g>
            <defs>
                <clipPath id="clip0_16_8">
                    <rect width="45.7982" height="39" fill="white" transform="translate(1 5)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default GooglePay