import React from 'react'
import styles from "../styles/logosStyles.module.css"

const Addidas = () => {
    return (
        <svg
            // width="48" height="48"
            className={styles.svgStyles}
            viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_237_346)">
                <path d="M42.5504 38.6783C40.4965 38.6783 39.2786 37.6272 39.1853 36.1136H41.149C41.149 36.6182 41.4286 37.2898 42.6436 37.3315C43.4856 37.3315 43.9516 36.8271 43.9516 36.4478C43.8584 35.8596 43.1096 35.8179 42.3639 35.6893C41.5219 35.5673 40.8694 35.3969 40.4002 35.188C39.7478 34.8505 39.3717 34.1338 39.3717 33.3786C39.3717 32.0318 40.4965 30.9808 42.4571 30.9808C44.3277 30.9808 45.5426 31.9484 45.6324 33.4203H43.7653C43.7653 33.0441 43.672 32.4111 42.5502 32.4111C41.8015 32.4111 41.3355 32.5364 41.2422 33.0828C41.2422 33.8413 42.83 33.7995 44.0449 34.092C45.1665 34.3877 45.9153 35.1012 45.9153 36.1136C45.9153 37.9648 44.4208 38.6783 42.5504 38.6783ZM6.76636 26.258L13.2136 22.5137L16.6717 28.4468H7.98123" fill="black" />
                <path d="M20.324 38.455V30.9666Z" fill="black" />
                <path d="M20.324 38.455V30.9666" stroke="black" stroke-width="1.88659" />
                <path d="M3.77423 34.8215C3.77423 35.9978 4.8026 36.9652 5.92101 36.9652C7.1391 36.9652 8.07116 35.9978 8.07116 34.8215C8.07116 33.687 7.1391 32.6778 5.92101 32.6778C4.8026 32.6778 3.77423 33.687 3.77423 34.8215ZM5.82791 38.6912C3.77408 38.6912 2 36.9652 2 34.8215C2 32.6778 3.77423 30.9938 5.82791 30.9938C6.66993 30.9938 7.41871 31.2059 8.07116 31.6654V31.0772H9.94171V38.5208H8.0713V38.058C7.5121 38.479 6.66993 38.6912 5.82791 38.6912ZM30.782 28.445L21.8119 12.7995L28.259 9.01343L39.4725 28.445M12.3715 34.8215C12.3715 35.9978 13.3069 36.9652 14.5216 36.9652C15.64 36.9652 16.6718 35.9978 16.6718 34.8215C16.6718 33.687 15.64 32.6778 14.5216 32.6778C13.3067 32.6778 12.3715 33.687 12.3715 34.8215ZM14.4284 38.6912C12.2782 38.6912 10.5974 36.9652 10.5974 34.8215C10.5974 32.6778 12.2782 30.9938 14.4284 30.9938C15.2672 30.9938 16.0161 31.2059 16.6718 31.6654V28.5125H18.5391V38.5208H16.6716V38.058C16.0159 38.479 15.2673 38.6912 14.4284 38.6912ZM14.3363 19.5708L20.8766 15.8716L28.0728 28.4446H21.2496V30.2958H19.3821V28.4446M44.7038 30.354C44.2346 30.354 43.7687 29.933 43.7687 29.4284C43.7687 28.9238 44.2346 28.5028 44.7038 28.5028C45.263 28.5028 45.6392 28.924 45.6392 29.4284C45.6392 29.9329 45.263 30.354 44.7038 30.354ZM44.7038 28.6315C44.2346 28.6315 43.955 29.0074 43.955 29.4284C43.955 29.8494 44.2346 30.2287 44.7038 30.2287C45.1699 30.2287 45.4495 29.8496 45.4495 29.4284C45.4495 29.0073 45.1699 28.6315 44.7038 28.6315Z" fill="black" />
                <path d="M44.8971 29.9357L44.7106 29.5147H44.5242V29.9357H44.3828V28.9265H44.8038C44.8971 28.9265 45.0834 29.0552 45.0834 29.2222C45.0834 29.3893 44.9902 29.473 44.8971 29.5147L45.0834 29.9357M44.7106 29.0552H44.5242V29.3894H44.7106C44.8971 29.3894 44.8971 29.3477 44.8971 29.2222C44.8971 29.1356 44.8971 29.0552 44.7106 29.0552ZM32.3626 34.821C32.3626 35.9972 33.298 36.9647 34.5127 36.9647C35.6344 36.9647 36.6597 35.9972 36.6597 34.821C36.6597 33.6865 35.6344 32.6773 34.5127 32.6773C33.2978 32.6773 32.3626 33.6865 32.3626 34.821ZM34.4164 38.6906C32.2695 38.6906 30.5885 36.9647 30.5885 34.821C30.5885 32.6773 32.2695 30.9932 34.4163 30.9932C35.2584 30.9932 36.0072 31.2054 36.6597 31.6649V31.0767H38.437V38.5202H36.6598V38.0575C36.0072 38.4785 35.2586 38.6906 34.4164 38.6906ZM23.7718 34.821C23.7718 35.9972 24.8002 36.9647 25.9218 36.9647C27.1368 36.9647 28.0719 35.9972 28.0719 34.821C28.0719 33.6865 27.1367 32.6773 25.9218 32.6773C24.8002 32.6773 23.7718 33.6865 23.7718 34.821ZM28.0719 28.512H29.9393V38.5202H28.0719V38.0575C27.5095 38.4785 26.7607 38.6906 25.9218 38.6906C23.7718 38.6906 21.9976 36.9647 21.9976 34.821C21.9976 32.6773 23.7718 30.9932 25.9218 30.9932C26.7606 30.9932 27.5095 31.2054 28.0719 31.6649V28.512Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_237_346">
                    <rect width="43.9154" height="29.7044" fill="white" transform="translate(2 9)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Addidas