import React from 'react'
import styles from "../styles/logosStyles.module.css"

const Olx = () => {
    return (
        <svg
            // width="48" height="48" 
            className={styles.svgStyles}
            viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_237_374)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24 46C11.8497 46 2 36.1503 2 24C2 11.8497 11.8497 2 24 2C36.1503 2 46 11.8497 46 24C46 36.1503 36.1503 46 24 46ZM23.9517 45.2755C35.7018 45.2755 45.2272 35.7501 45.2272 24C45.2272 12.2499 35.7018 2.72448 23.9517 2.72448C12.2016 2.72448 2.67618 12.2499 2.67618 24C2.67618 35.7501 12.2016 45.2755 23.9517 45.2755Z" fill="#9B9B9B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0561 32.8146C7.09497 32.8146 3.88379 29.0196 3.88379 24.3382C3.88379 19.6568 7.09497 15.8618 11.0561 15.8618C15.0173 15.8618 18.2285 19.6568 18.2285 24.3382C18.2285 29.0196 15.0173 32.8146 11.0561 32.8146ZM11.0561 30.0616C12.91 30.0616 14.4129 27.4883 14.4129 24.3141C14.4129 21.1398 12.91 18.5665 11.0561 18.5665C9.20223 18.5665 7.69938 21.1398 7.69938 24.3141C7.69938 27.4883 9.20223 30.0616 11.0561 30.0616Z" fill="#642286" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1577 25.8961C19.1577 24.778 18.9658 15.4067 18.9658 14.6526C18.9658 13.8985 19.6285 13.5208 20.0896 13.5208H21.7793C22.1795 13.5208 22.9865 13.6603 22.9865 14.9078C22.9865 16.1554 23.1189 22.489 23.1189 22.9264C23.1189 23.3637 23.3825 23.8422 24.278 23.8422H27.4995C27.9379 23.8422 28.3556 24.1194 28.3556 24.8765C28.3556 25.6335 28.2975 26.5025 28.1802 27.0075C28.0629 27.5127 27.7021 27.7527 26.8091 27.7527H20.9616C20.0896 27.7527 19.1577 27.0141 19.1577 25.8961Z" fill="#A9B900" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M28.1745 28.1335C28.711 27.5475 30.0611 26.043 32.2249 23.62C30.4385 20.0759 29.4202 18.0223 29.1702 17.459C28.7951 16.6142 29.6602 15.6226 30.4318 15.6226C31.2034 15.6226 31.9753 15.8886 32.5028 16.6738C32.8546 17.1973 33.6823 18.4251 34.9861 20.3572C37.1319 17.9704 38.3428 16.5841 38.6189 16.1983C39.033 15.6195 40.3016 15.0525 40.9161 15.4147C41.5306 15.7768 41.5431 16.5043 40.8143 17.5805C40.3284 18.2979 39.025 20.2103 36.9042 23.3176C40.2816 28.2664 42.0948 30.9536 42.3441 31.3791C42.7179 32.0175 42.6248 32.8214 41.5703 33.1233C40.5157 33.4253 39.7071 33.6164 39.0085 33.7789C38.3098 33.9414 37.6408 33.8597 36.9042 32.5517C36.4131 31.6797 35.478 29.9596 34.0989 27.3915C32.1683 30.3038 31.0204 32.0239 30.6551 32.5517C30.1071 33.3434 29.2977 33.5033 28.7912 32.7163C28.2848 31.9293 28.0484 31.5346 27.661 30.7742C27.2735 30.014 27.3697 29.0124 28.1745 28.1335Z" fill="#FE7701" />
            </g>
            <defs>
                <clipPath id="clip0_237_374">
                    <rect width="44" height="44" fill="white" transform="translate(2 2)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Olx