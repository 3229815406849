import React from 'react'
import styles from "../styles/logosStyles.module.css"

const FedEx = () => {
    return (
        <svg
            // width="48" height="48" 
            className={styles.svgStyles}
            viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_21_303)">
                <path d="M45.7296 28.6109C45.7296 27.933 45.2489 27.4367 44.5867 27.4367C43.9256 27.4367 43.4444 27.933 43.4444 28.6109C43.4444 29.2877 43.9256 29.7832 44.5867 29.7832C45.2489 29.7832 45.7296 29.2868 45.7296 28.6109ZM44.2864 28.6711V29.4381H44.0387V27.7469H44.6544C45.0152 27.7469 45.1807 27.9044 45.1807 28.2116C45.1807 28.4008 45.0528 28.5507 44.8881 28.5724V28.5796C45.0311 28.6024 45.0836 28.73 45.1131 28.9634C45.1356 29.1147 45.1656 29.377 45.2195 29.4386H44.9263C44.8581 29.2797 44.865 29.0547 44.8125 28.8664C44.769 28.7231 44.6926 28.6709 44.5284 28.6709H44.2878V28.6717L44.2864 28.6711ZM44.5936 28.4525C44.8342 28.4525 44.903 28.3244 44.903 28.2113C44.903 28.0777 44.8342 27.9721 44.5936 27.9721H44.2864V28.4531H44.5936V28.4525ZM43.1719 28.6109C43.1719 27.7757 43.8349 27.2112 44.5856 27.2112C45.3386 27.2112 46.0007 27.7757 46.0007 28.6109C46.0007 29.4444 45.3388 30.0095 44.5856 30.0095C43.8349 30.0095 43.1719 29.4444 43.1719 28.6109Z" fill="#FF5A00" />
                <path d="M38.2719 29.7658L36.642 27.9379L35.0264 29.7658H31.6269L34.9494 26.033L31.6269 22.2991H35.1326L36.779 24.113L38.3632 22.2991H41.7462L38.4402 26.0173L41.7921 29.7658H38.2719ZM25.0991 29.7658V18.0034H31.6269V20.6252H27.8642V22.2991H31.6269V24.8219H27.8642V27.1374H31.6269V29.7658H25.0991Z" fill="#FF5A00" />
                <path d="M22.3441 18.0034V22.8172H22.3136C21.7039 22.1165 20.9419 21.8723 20.0584 21.8723C18.2478 21.8723 16.8838 23.104 16.4051 24.7312C15.8589 22.9379 14.4507 21.8388 12.3629 21.8388C10.667 21.8388 9.32834 22.5997 8.62903 23.8399V22.2988H5.12422V20.6255H8.94939V18.004H2V29.7658H5.12422V24.8219H8.23826C8.14234 25.2021 8.09439 25.5928 8.09554 25.9849C8.09554 28.4373 9.96963 30.1607 12.3624 30.1607C14.3737 30.1607 15.7002 29.2155 16.4012 27.4949H13.7236C13.3617 28.0127 13.0867 28.1656 12.3626 28.1656C11.5233 28.1656 10.799 27.433 10.799 26.5657H16.2511C16.4876 28.514 18.0055 30.1939 20.0889 30.1939C20.9876 30.1939 21.8104 29.752 22.3133 29.0054H22.3436V29.7674H25.0982V18.004H22.3441V18.0034ZM10.8859 24.8571C11.06 24.1097 11.6394 23.6213 12.3626 23.6213C13.159 23.6213 13.709 24.0946 13.8534 24.8571H10.8859ZM20.6724 27.9758C19.6574 27.9758 19.0266 27.0301 19.0266 26.0423C19.0266 24.9864 19.5755 23.9719 20.6724 23.9719C21.8106 23.9719 22.2641 24.9869 22.2641 26.0423C22.2641 27.0428 21.784 27.9758 20.6724 27.9758Z" fill="#29007C" />
            </g>
            <defs>
                <clipPath id="clip0_21_303">
                    <rect width="44" height="12.1968" fill="white" transform="translate(2 18)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default FedEx