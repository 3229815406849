import React from 'react'
import styles from "./styles/iconStyles.module.css"
import { useSelector, useDispatch } from 'react-redux'

const theme = 'dark';
const SettingsIcon = () => {
    const isDarkMode = useSelector((state) => state.ui.darkMode);

    return (

        <>

            {isDarkMode === true ?

                <svg
                    className={styles.IconStyles}
                    viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M12 1.25C11.0883 1.25 10.2021 1.36366 9.35523 1.57796C8.66403 1.75286 8.30712 2.3498 8.19231 2.84601C8.05631 3.43379 7.68745 3.96329 7.12507 4.28798C6.60799 4.58651 6.02339 4.65664 5.48422 4.5295C4.98869 4.41265 4.2968 4.4683 3.83377 5.00867C2.9729 6.01334 2.29381 7.17934 1.84824 8.45567C1.58222 9.21766 1.99438 9.9038 2.42955 10.2623C2.93186 10.6762 3.25005 11.3006 3.25005 12C3.25005 12.6994 2.93186 13.3238 2.42955 13.7376C1.99438 14.0962 1.58222 14.7823 1.84824 15.5443C2.29376 16.8205 2.97275 17.9864 3.83348 18.991C4.29654 19.5314 4.98851 19.5871 5.48408 19.4702C6.02329 19.343 6.60795 19.4131 7.12506 19.7117C7.68751 20.0364 8.05639 20.566 8.19235 21.1538C8.30713 21.6501 8.66404 22.2471 9.35531 22.4221C10.2022 22.6363 11.0884 22.75 12 22.75C12.9117 22.75 13.798 22.6363 14.6449 22.422C15.3361 22.2471 15.693 21.6501 15.8078 21.1538C15.9438 20.566 16.3126 20.0364 16.8751 19.7117C17.3922 19.4131 17.9768 19.343 18.516 19.4702C19.0116 19.587 19.7035 19.5314 20.1666 18.991C21.0273 17.9864 21.7063 16.8205 22.1518 15.5443C22.4178 14.7823 22.0057 14.0962 21.5705 13.7376C21.0682 13.3238 20.75 12.6994 20.75 12C20.75 11.3006 21.0682 10.6762 21.5705 10.2624C22.0057 9.90381 22.4178 9.21767 22.1518 8.45569C21.7063 7.17937 21.0272 6.01337 20.1663 5.00871C19.7033 4.46835 19.0114 4.41269 18.5159 4.52952C17.9768 4.65663 17.3922 4.5865 16.8751 4.28798C16.3128 3.96329 15.9439 3.43381 15.8079 2.84603C15.6931 2.34983 15.3362 1.75292 14.645 1.578C13.7981 1.36368 12.9118 1.25 12 1.25Z" fill="white" />
                    <circle cx="3" cy="3" r="3" transform="matrix(-1 0 0 1 15 9)" fill="white" />
                </svg>
                :
                <svg
                    className={styles.IconStyles}
                    viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" d="M12 1.25C11.0883 1.25 10.2021 1.36366 9.35523 1.57796C8.66403 1.75286 8.30712 2.3498 8.19231 2.84601C8.05631 3.43379 7.68745 3.96329 7.12507 4.28798C6.60799 4.58651 6.02339 4.65664 5.48422 4.5295C4.98869 4.41265 4.2968 4.4683 3.83377 5.00867C2.9729 6.01334 2.29381 7.17934 1.84824 8.45567C1.58222 9.21766 1.99438 9.9038 2.42955 10.2623C2.93186 10.6762 3.25005 11.3006 3.25005 12C3.25005 12.6994 2.93186 13.3238 2.42955 13.7376C1.99438 14.0962 1.58222 14.7823 1.84824 15.5443C2.29376 16.8205 2.97275 17.9864 3.83348 18.991C4.29654 19.5314 4.98851 19.5871 5.48408 19.4702C6.02329 19.343 6.60795 19.4131 7.12506 19.7117C7.68751 20.0364 8.05639 20.566 8.19235 21.1538C8.30713 21.6501 8.66404 22.2471 9.35531 22.4221C10.2022 22.6363 11.0884 22.75 12 22.75C12.9117 22.75 13.798 22.6363 14.6449 22.422C15.3361 22.2471 15.693 21.6501 15.8078 21.1538C15.9438 20.566 16.3126 20.0364 16.8751 19.7117C17.3922 19.4131 17.9768 19.343 18.516 19.4702C19.0116 19.587 19.7035 19.5314 20.1666 18.991C21.0273 17.9864 21.7063 16.8205 22.1518 15.5443C22.4178 14.7823 22.0057 14.0962 21.5705 13.7376C21.0682 13.3238 20.75 12.6994 20.75 12C20.75 11.3006 21.0682 10.6762 21.5705 10.2624C22.0057 9.90381 22.4178 9.21767 22.1518 8.45569C21.7063 7.17937 21.0272 6.01337 20.1663 5.00871C19.7033 4.46835 19.0114 4.41269 18.5159 4.52952C17.9768 4.65663 17.3922 4.5865 16.8751 4.28798C16.3128 3.96329 15.9439 3.43381 15.8079 2.84603C15.6931 2.34983 15.3362 1.75292 14.645 1.578C13.7981 1.36368 12.9118 1.25 12 1.25Z" fill="#2B3F6C" />
                    <circle cx="3" cy="3" r="3" transform="matrix(-1 0 0 1 15 9)" fill="#2B3F6C" />
                </svg>



            }
        </>


    )
}

export default SettingsIcon