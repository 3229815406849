import React from 'react'
import styles from "../styles/logosStyles.module.css"

const Citibank = () => {
    return (
        <svg
            // width="47" height="47" 
            className={styles.svgStyles}
            viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_21_337)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.979004 6C0.979004 3.23858 3.21758 1 5.979 1H41.0207C43.7821 1 46.0207 3.23858 46.0207 6V41.0417C46.0207 43.8031 43.7821 46.0417 41.0207 46.0417H5.97901C3.21758 46.0417 0.979004 43.8031 0.979004 41.0417V6Z" fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.6918 18.4365H19.4518V34.2812H22.6918V18.4365ZM25.6626 18.4627V21.4334H28.3904V30.3723C28.3904 31.6056 28.729 32.5962 29.3805 33.3431C30.0321 34.1164 30.9008 34.4982 31.9954 34.5244C33.0808 34.5505 34.0453 34.3071 34.8621 33.8033L34.9139 33.7772V33.725L35.6872 30.7106L35.496 30.8321C34.6274 31.3622 33.8284 31.6228 33.0985 31.6228C32.0909 31.6228 31.5787 31.0235 31.5787 29.842V21.4337H34.888V18.4627H31.5787V13.5112L28.3904 15.2226V18.4627H25.6626V18.4627ZM41.3424 18.4365H38.0762V34.2812H41.3424V18.4365ZM17.5928 20.9299L17.5231 20.8776C16.029 19.0622 14.1614 18.176 11.9201 18.176C9.57479 18.176 7.64616 18.897 6.09982 20.3216C4.45812 21.8244 3.63281 23.8484 3.63281 26.3849C3.63281 28.9213 4.45812 30.9283 6.09982 32.4485C7.62004 33.873 9.57458 34.5768 11.9201 34.5768C14.1614 34.5768 16.0462 33.6821 17.5231 31.8925L17.5928 31.8403L15.7597 29.6252L15.6903 29.6944C14.5001 30.8757 13.2233 31.4839 11.8507 31.4839C10.4173 31.4839 9.23583 30.9974 8.29775 30.0334C7.35074 29.0864 6.89922 27.8613 6.89922 26.3847C6.89922 24.908 7.35074 23.6743 8.29775 22.7103C9.23583 21.772 10.4173 21.2856 11.8507 21.2856C13.2231 21.2856 14.5001 21.8937 15.6903 23.0749L15.7597 23.1446L17.5928 20.9299V20.9299Z" fill="#36377C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0497 15.8305C22.3268 14.5275 23.7514 13.5372 25.3759 12.8336C26.9397 12.1561 28.6073 11.8258 30.3274 11.8258C32.0389 11.8258 33.7063 12.1558 35.3051 12.8336C36.8946 13.5372 38.319 14.5275 39.5963 15.8305L39.6485 15.874H43.3665L43.2276 15.6569C41.7509 13.5807 39.8395 11.939 37.5464 10.7401C35.2529 9.54994 32.864 8.96802 30.3276 8.96802C27.8172 8.96802 25.3936 9.54994 23.1001 10.7401C20.8066 11.9388 18.8956 13.5805 17.4274 15.6567L17.2798 15.8738H21.0238L21.0497 15.8305V15.8305Z" fill="#CC2131" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.6918 18.4365H19.4518V34.2812H22.6918V18.4365ZM25.6626 18.4627V21.4334H28.3904V30.3723C28.3904 31.6056 28.729 32.5962 29.3805 33.3431C30.0321 34.1164 30.9008 34.4982 31.9954 34.5244C33.0808 34.5505 34.0453 34.3071 34.8621 33.8033L34.9139 33.7772V33.725L35.6872 30.7106L35.496 30.8321C34.6274 31.3622 33.8284 31.6228 33.0985 31.6228C32.0909 31.6228 31.5787 31.0235 31.5787 29.842V21.4337H34.888V18.4627H31.5787V13.5112L28.3904 15.2226V18.4627H25.6626V18.4627ZM41.3424 18.4365H38.0762V34.2812H41.3424V18.4365ZM17.5928 20.9299L17.5231 20.8776C16.029 19.0622 14.1614 18.176 11.9201 18.176C9.57479 18.176 7.64616 18.897 6.09982 20.3216C4.45812 21.8244 3.63281 23.8484 3.63281 26.3849C3.63281 28.9213 4.45812 30.9283 6.09982 32.4485C7.62004 33.873 9.57458 34.5768 11.9201 34.5768C14.1614 34.5768 16.0462 33.6821 17.5231 31.8925L17.5928 31.8403L15.7597 29.6252L15.6903 29.6944C14.5001 30.8757 13.2233 31.4839 11.8507 31.4839C10.4173 31.4839 9.23583 30.9974 8.29775 30.0334C7.35074 29.0864 6.89922 27.8613 6.89922 26.3847C6.89922 24.908 7.35074 23.6743 8.29775 22.7103C9.23583 21.772 10.4173 21.2856 11.8507 21.2856C13.2231 21.2856 14.5001 21.8937 15.6903 23.0749L15.7597 23.1446L17.5928 20.9299V20.9299Z" fill="#36377C" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0497 15.8305C22.3268 14.5275 23.7514 13.5372 25.3759 12.8336C26.9397 12.1561 28.6073 11.8258 30.3274 11.8258C32.0389 11.8258 33.7063 12.1558 35.3051 12.8336C36.8946 13.5372 38.319 14.5275 39.5963 15.8305L39.6485 15.874H43.3665L43.2276 15.6569C41.7509 13.5807 39.8395 11.939 37.5464 10.7401C35.2529 9.54994 32.864 8.96802 30.3276 8.96802C27.8172 8.96802 25.3936 9.54994 23.1001 10.7401C20.8066 11.9388 18.8956 13.5805 17.4274 15.6567L17.2798 15.8738H21.0238L21.0497 15.8305V15.8305Z" fill="#CA3132" />
            </g>
            <defs>
                <clipPath id="clip0_21_337">
                    <rect width="45.0417" height="45.0417" fill="white" transform="translate(0.979004 1)" />
                </clipPath>
            </defs>
        </svg>


    )
}

export default Citibank