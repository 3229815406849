import React from 'react'
import styles from "../styles/logosStyles.module.css"

const Dribble = () => {
    return (
        <svg
            // width="48" height="48" 
            className={styles.svgStyles}
            viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_125_17)">
                <path d="M44.4877 23.1648C44.7264 28.6548 42.7372 34.2244 38.5202 38.4414C38.1224 38.8393 37.645 39.2371 37.2472 39.6349C35.6559 34.861 33.5076 30.8827 31.1206 27.4613C35.0193 24.4378 39.7933 23.0056 44.4877 23.1648Z" fill="#EE6D9E" />
                <path d="M30.0069 28.4165C32.3939 31.8378 34.4626 35.8161 36.0539 40.5901C32.2348 43.2953 27.7791 44.5684 23.3234 44.4093C23.0847 38.9192 25.0738 33.3496 29.2908 29.1326C29.45 28.8939 29.7682 28.6552 30.0069 28.4165Z" fill="#EE6D9E" />
                <path d="M7.25068 12.1047C10.1151 12.98 14.3321 14.6508 18.8673 17.6744C18.7082 17.9131 18.4695 18.0722 18.3104 18.3109C14.2525 22.3688 8.842 24.3579 3.51108 24.2783C3.43151 19.9818 4.70457 15.7648 7.25068 12.1047Z" fill="#EA4B8C" />
                <path d="M24.2789 3.43239C24.3584 8.04722 22.8467 12.7416 19.8232 16.5608C15.3675 13.6168 11.1505 11.8664 8.20654 10.9116C8.60437 10.4342 9.08177 9.9568 9.4796 9.4794C13.617 5.42154 18.948 3.35282 24.2789 3.43239Z" fill="#EA4B8C" />
                <path d="M28.1777 28.0977C23.722 32.5534 21.5737 38.4413 21.7328 44.3292C17.2771 43.8518 12.901 41.8626 9.47966 38.4413C5.89919 34.8608 3.9896 30.3255 3.51221 25.7107C9.24096 25.7903 14.9697 23.642 19.2663 19.2659C19.505 19.0272 19.7437 18.7885 19.9824 18.4702C23.0854 20.6981 26.1885 23.5624 28.9733 27.2225C28.8142 27.5407 28.4959 27.859 28.1777 28.0977Z" fill="#EA4B8C" />
                <path d="M44.4088 21.7324C39.3961 21.5732 34.3835 23.085 30.246 26.3472C27.3817 22.6076 24.199 19.7432 21.0959 17.4358C24.2786 13.3779 25.8699 8.44483 25.7903 3.59131C30.4847 3.98914 35.02 5.97829 38.5209 9.47919C41.9422 12.9005 43.9314 17.2767 44.4088 21.7324Z" fill="#EA4B8C" />
                <path d="M38.5208 38.4412C42.7378 34.2242 44.7269 28.6546 44.4882 23.1646C39.7939 23.0054 35.0199 24.4376 31.1212 27.4611C33.5081 30.8825 35.6564 34.8608 37.2477 39.6347C37.7251 39.3165 38.123 38.9186 38.5208 38.4412ZM21.0958 17.4358C24.2785 19.7432 27.4611 22.6076 30.2459 26.3472C34.3834 23.1646 39.396 21.5732 44.4087 21.7324C43.9313 17.2767 41.9421 12.9005 38.5208 9.4792C34.9403 5.89873 30.4051 3.98915 25.7902 3.59132C25.7902 8.44485 24.2785 13.3779 21.0958 17.4358ZM19.8228 16.5606C22.8463 12.7414 24.358 8.12658 24.2785 3.43219C18.9476 3.35262 13.6166 5.42134 9.4792 9.4792C9.00181 9.9566 8.60398 10.434 8.20615 10.9114C11.1501 11.8662 15.4467 13.6166 19.8228 16.5606ZM23.2441 44.4882C27.6998 44.6474 32.2351 43.3743 35.9747 40.6691C34.4629 35.8951 32.3146 31.8372 29.9277 28.4955C29.689 28.7342 29.3707 28.9729 29.132 29.2116C24.9946 33.349 23.0054 38.9186 23.2441 44.4882ZM8.44485 39.5552C-0.148282 30.962 -0.148282 16.9584 8.44485 8.44485C17.038 -0.148282 30.962 -0.148282 39.5552 8.44485C48.1483 17.038 48.1483 30.962 39.5552 39.5552C30.962 48.1483 17.038 48.1483 8.44485 39.5552ZM7.25136 12.1049C4.70524 15.6854 3.43219 19.9819 3.51175 24.1989C8.84268 24.2785 14.2532 22.2893 18.311 18.2315C18.4702 18.0723 18.7089 17.8336 18.868 17.5949C14.4123 14.651 10.1157 12.9801 7.25136 12.1049ZM19.3454 19.3454C14.9693 23.7215 9.32007 25.8698 3.59132 25.7902C3.98915 30.4051 5.9783 34.9403 9.55877 38.5208C12.9801 41.9421 17.3562 43.9313 21.8119 44.4087C21.5732 38.5208 23.7215 32.5533 28.1772 28.0976C28.4955 27.7794 28.8137 27.4611 29.132 27.2224C26.3472 23.5624 23.1646 20.7776 20.141 18.5497C19.9023 18.7884 19.5841 19.0271 19.3454 19.3454Z" fill="#42210B" />
                <path d="M44.4088 21.7325C43.9314 17.2768 41.9422 12.9007 38.5209 9.47938C36.6909 7.64936 34.5426 6.21717 32.3148 5.26238C31.4395 5.10325 30.4847 5.02368 29.5299 5.02368C28.2569 5.02368 26.9838 5.18281 25.7108 5.34195C25.3925 9.63851 23.8808 13.8555 21.0959 17.3564C24.2786 19.5843 27.4612 22.5282 30.246 26.2678C34.3835 23.0852 39.3961 21.5734 44.4088 21.7325Z" fill="#EE6D9E" />
                <path d="M19.8228 16.5607C22.3689 13.3781 23.8011 9.55891 24.1989 5.73975C19.8228 6.93324 16.0036 9.47935 13.2983 12.9007C15.3671 13.8555 17.5949 15.049 19.8228 16.5607Z" fill="#EE6D9E" />
                <path d="M8.92163 23.6422C12.343 22.7669 15.6052 21.0165 18.3104 18.3112C18.4696 18.1521 18.7082 17.9134 18.8674 17.6747C16.6395 16.163 14.4117 14.9695 12.4225 14.0942C10.513 16.879 9.31946 20.1413 8.92163 23.6422Z" fill="#EE6D9E" />
                <path d="M20.1404 18.55C19.9017 18.7887 19.663 19.107 19.4243 19.3457C16.4008 22.3692 12.7408 24.2788 8.92163 25.154C8.92163 25.3132 8.92163 25.5519 8.92163 25.711C8.92163 33.7472 13.4569 40.6694 20.1404 44.0907C20.6974 44.1703 21.2544 44.3294 21.8909 44.3294C21.5726 38.5211 23.7209 32.5537 28.1766 28.098C28.4949 27.7797 28.8131 27.4614 29.1314 27.2227C26.3466 23.5627 23.1639 20.7779 20.1404 18.55Z" fill="#EE6D9E" />
            </g>
            <defs>
                <clipPath id="clip0_125_17">
                    <rect width="44" height="44" fill="white" transform="translate(2 2)" />
                </clipPath>
            </defs>
        </svg>


    )
}

export default Dribble