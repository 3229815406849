import React from 'react'
import styles from "../styles/logosStyles.module.css"
const Spotify = () => {
    return (

        <svg
            className={styles.svgStyles}
            // width="48" height="48"
            viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_21_340)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M35.6264 20.1737C29.0182 16.2495 18.1188 15.8885 11.8104 17.8031C10.7974 18.1107 9.72618 17.5387 9.41921 16.5257C9.11224 15.5121 9.68362 14.4415 10.6975 14.1336C17.9386 11.9356 29.9763 12.36 37.5835 16.8758C38.4948 17.4167 38.7939 18.5936 38.2536 19.503C37.713 20.4143 36.5355 20.7146 35.6264 20.1737ZM35.41 25.9862C34.9466 26.7384 33.9629 26.9742 33.2117 26.5123C27.7025 23.126 19.302 22.145 12.7841 24.1236C11.939 24.3789 11.0461 23.9023 10.7896 23.0587C10.5348 22.2136 11.0117 21.3225 11.8554 21.0654C19.3008 18.8061 28.5567 19.9003 34.8842 23.7889C35.6354 24.2516 35.8718 25.2356 35.41 25.9862ZM32.9017 31.5682C32.5331 32.1721 31.7465 32.3614 31.1447 31.9934C26.3309 29.0511 20.2715 28.3868 13.1358 30.0167C12.4482 30.1743 11.7627 29.7432 11.606 29.0559C11.4485 28.3684 11.8777 27.6829 12.5671 27.5259C20.376 25.7408 27.0744 26.509 32.4776 29.8111C33.0801 30.1788 33.2699 30.966 32.9017 31.5682ZM23.5 2C12.1783 2 3 11.178 3 22.4997C3 33.8226 12.1783 43 23.5 43C34.822 43 44 33.8226 44 22.4997C44 11.178 34.822 2 23.5 2Z" fill="#1ED760" />
            </g>
            <defs>
                <clipPath id="clip0_21_340">
                    <rect width="41" height="41" fill="white" transform="translate(3 2)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Spotify