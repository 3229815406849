import React from 'react'
import styles from "../styles/logosStyles.module.css"

const GoogleMaps = () => {
    return (
        <svg
            // width="48" height="48"
            className={styles.svgStyles}
            viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_21_298)">
                <path d="M28.5657 2.56242C27.1018 2.1006 25.5334 1.8479 23.9126 1.8479C19.1811 1.8479 14.9462 3.98276 12.1143 7.34625L19.3902 13.4633L28.5657 2.56242Z" fill="#1A73E8" />
                <path d="M12.1138 7.34595C9.78112 10.1166 8.5033 13.6229 8.50635 17.2447C8.50635 20.1377 9.08146 22.4904 10.0313 24.5904L19.3898 13.463L12.1138 7.34595Z" fill="#EA4335" />
                <path d="M23.9126 11.3632C25.0359 11.3642 26.1354 11.6862 27.0818 12.2912C28.0282 12.8962 28.782 13.759 29.2545 14.7781C29.7269 15.7971 29.8983 16.93 29.7485 18.0432C29.5987 19.1564 29.1339 20.2036 28.4089 21.0615L37.5757 10.1607C35.6941 6.53555 32.4503 3.80571 28.557 2.57104L19.3989 13.4719C19.9507 12.8111 20.6411 12.2796 21.4211 11.9152C22.2011 11.5508 23.0517 11.3623 23.9126 11.3632Z" fill="#4285F4" />
                <path d="M23.9127 23.1441C20.6625 23.1441 18.0222 20.5039 18.0222 17.2537C18.0184 15.8698 18.5063 14.5295 19.399 13.4719L10.0317 24.5993C11.6351 28.1458 14.2927 30.9952 17.0288 34.5852L28.4089 21.0616C27.8561 21.7148 27.1673 22.2396 26.3907 22.5993C25.6142 22.959 24.7685 23.1449 23.9127 23.1441Z" fill="#FBBC04" />
                <path d="M28.1819 38.2709C33.323 30.2369 39.3093 26.5858 39.3093 17.2447C39.3093 14.6829 38.682 12.2692 37.5753 10.1431L17.0371 34.585C17.9085 35.7265 18.7886 36.9377 19.6425 38.2796C22.762 43.107 21.8994 46 23.9122 46C25.9251 46 25.0624 43.0983 28.1819 38.2709Z" fill="#34A853" />
            </g>
            <defs>
                <clipPath id="clip0_21_298">
                    <rect width="46" height="46" fill="white" transform="translate(2)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default GoogleMaps