import React from 'react'
import styles from "../styles/logosStyles.module.css"

const Tiktok = () => {
    return (
        <svg
            // width="48" height="48"
            className={styles.svgStyles}
            viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_21_256)">
                <path d="M20.1363 21.0658V19.5788C19.6201 19.4971 19.0988 19.4523 18.5763 19.4449C13.5174 19.4341 9.0387 22.7123 7.51953 27.5377C6.00035 32.363 7.79356 37.6157 11.9459 40.5049C10.4232 38.8752 9.40478 36.8397 9.01346 34.644C8.62214 32.4483 8.87469 30.1863 9.74064 28.131C10.6066 26.0756 12.0489 24.3149 13.8935 23.0612C15.7381 21.8076 17.9062 21.1146 20.1359 21.0659L20.1363 21.0658Z" fill="#25F4EE" />
                <path d="M20.4157 37.909C23.2441 37.9051 25.569 35.6769 25.6929 32.8511V7.6352H30.2995C30.2055 7.10829 30.1606 6.57378 30.1655 6.03857L23.8646 6.03857V31.2302C23.7598 34.0703 21.4293 36.3199 18.5874 36.3245C17.7381 36.3173 16.9027 36.1084 16.1499 35.7152C16.6378 36.3911 17.2785 36.9421 18.0198 37.3234C18.7611 37.7046 19.5821 37.9053 20.4157 37.909V37.909ZM38.9039 16.1907V14.7891C37.2085 14.7898 35.5507 14.2895 34.1387 13.3511C35.3765 14.7901 37.0492 15.7869 38.9039 16.1907V16.1907Z" fill="#25F4EE" />
                <path d="M34.1394 13.3518C32.7483 11.7678 31.9815 9.7316 31.9822 7.62354H30.3002C30.5174 8.78906 30.9699 9.89809 31.63 10.883C32.29 11.8678 33.1439 12.7078 34.1394 13.3518V13.3518ZM18.5759 25.7464C17.3963 25.7524 16.2525 26.1526 15.3264 26.8834C14.4003 27.6141 13.745 28.6335 13.4648 29.7794C13.1846 30.9253 13.2956 32.132 13.78 33.2077C14.2644 34.2833 15.0945 35.1661 16.1383 35.7158C15.5676 34.9278 15.2258 33.9974 15.1508 33.0274C15.0757 32.0574 15.2703 31.0855 15.7129 30.2191C16.1556 29.3527 16.8292 28.6256 17.6592 28.118C18.4893 27.6104 19.4434 27.3422 20.4164 27.3429C20.9453 27.3498 21.4705 27.4322 21.9762 27.5874V21.1761C21.4598 21.0987 20.9385 21.058 20.4164 21.0543H20.1359V25.9292C19.6276 25.7928 19.102 25.7313 18.5759 25.7464V25.7464Z" fill="#FE2C55" />
                <path d="M38.9053 16.1904V21.0655C35.7667 21.0594 32.7095 20.0659 30.1669 18.2258V31.0349C30.1534 37.4264 24.9682 42.6009 18.5766 42.6009C16.2028 42.6051 13.8862 41.8727 11.9463 40.5046C13.5282 42.206 15.5857 43.3917 17.851 43.9071C20.1163 44.4224 22.4843 44.2437 24.6466 43.3941C26.8089 42.5445 28.6652 41.0634 29.9737 39.1438C31.2822 37.2242 31.9824 34.955 31.9829 32.6318V19.859C34.5341 21.687 37.595 22.6676 40.7334 22.6621V16.385C40.119 16.3832 39.5064 16.318 38.9053 16.1904V16.1904Z" fill="#FE2C55" />
                <path d="M30.1662 31.0358V18.2267C32.7165 20.0564 35.778 21.037 38.9167 21.0298V16.1549C37.0624 15.7633 35.3857 14.7795 34.1393 13.3518C33.1438 12.7078 32.29 11.8678 31.6299 10.883C30.9699 9.89809 30.5174 8.78906 30.3001 7.62354H25.6935V32.8521C25.6487 33.9503 25.2629 35.0072 24.5897 35.876C23.9165 36.7447 22.9894 37.3822 21.9372 37.6999C20.8851 38.0175 19.7601 37.9995 18.7186 37.6484C17.6772 37.2972 16.771 36.6303 16.1259 35.7404C15.082 35.1909 14.2517 34.308 13.7672 33.2323C13.2826 32.1566 13.1716 30.9498 13.4518 29.8038C13.732 28.6577 14.3874 27.6383 15.3136 26.9075C16.2398 26.1767 17.3838 25.7766 18.5636 25.7707C19.0927 25.7755 19.6182 25.8576 20.1236 26.0146V21.1395C17.8818 21.1775 15.6995 21.867 13.8428 23.1238C11.9861 24.3806 10.5352 26.1505 9.66704 28.2176C8.79883 30.2848 8.55083 32.5599 8.95329 34.7656C9.35574 36.9712 10.3913 39.0122 11.9336 40.6395C13.8925 41.9625 16.2125 42.6479 18.5759 42.6018C24.9674 42.6018 30.1527 37.4273 30.1662 31.0358V31.0358Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_21_256">
                    <rect width="48" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>


    )
}

export default Tiktok