import React from 'react'
import styles from "../styles/logosStyles.module.css"

const Reddit = () => {
    return (
        <svg
            // width="48" height="48" 
            className={styles.svgStyles}
            viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_21_335)">
                <path d="M43.988 2H3.01201C3.00538 2 3 2.00538 3 2.01201V42.988C3 42.9946 3.00538 43 3.01201 43H43.988C43.9946 43 44 42.9946 44 42.988V2.01201C44 2.00538 43.9946 2 43.988 2Z" fill="#FF4400" />
                <path d="M23.5 35.9531C30.8415 35.9531 36.793 31.7584 36.793 26.584C36.793 21.4096 30.8415 17.2148 23.5 17.2148C16.1585 17.2148 10.207 21.4096 10.207 26.584C10.207 31.7584 16.1585 35.9531 23.5 35.9531Z" fill="white" />
                <path d="M11.4883 25.8633C13.3458 25.8633 14.8516 24.3575 14.8516 22.5C14.8516 20.6425 13.3458 19.1367 11.4883 19.1367C9.63079 19.1367 8.125 20.6425 8.125 22.5C8.125 24.3575 9.63079 25.8633 11.4883 25.8633Z" fill="white" />
                <path d="M35.5918 25.8633C37.4493 25.8633 38.9551 24.3575 38.9551 22.5C38.9551 20.6425 37.4493 19.1367 35.5918 19.1367C33.7343 19.1367 32.2285 20.6425 32.2285 22.5C32.2285 24.3575 33.7343 25.8633 35.5918 25.8633Z" fill="white" />
                <path d="M33.0293 13.6914C34.4445 13.6914 35.5918 12.5441 35.5918 11.1289C35.5918 9.71368 34.4445 8.56641 33.0293 8.56641C31.6141 8.56641 30.4668 9.71368 30.4668 11.1289C30.4668 12.5441 31.6141 13.6914 33.0293 13.6914Z" fill="white" />
                <path d="M23.5 17.6953L25.3418 9.60742L31.1875 10.8086" stroke="white" stroke-width="1.28125" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M18.2949 30.748C20.9375 32.75 26.0625 32.8301 28.7051 30.748" stroke="#FF4400" stroke-width="1.04102" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M18.2949 27.4648C19.6659 27.4648 20.7773 26.3534 20.7773 24.9824C20.7773 23.6114 19.6659 22.5 18.2949 22.5C16.9239 22.5 15.8125 23.6114 15.8125 24.9824C15.8125 26.3534 16.9239 27.4648 18.2949 27.4648Z" fill="#FF4400" />
                <path d="M28.7051 27.4648C30.0761 27.4648 31.1875 26.3534 31.1875 24.9824C31.1875 23.6114 30.0761 22.5 28.7051 22.5C27.3341 22.5 26.2227 23.6114 26.2227 24.9824C26.2227 26.3534 27.3341 27.4648 28.7051 27.4648Z" fill="#FF4400" />
            </g>
            <defs>
                <clipPath id="clip0_21_335">
                    <rect width="41" height="41" fill="white" transform="translate(3 2)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Reddit