import React from 'react'
import styles from "../styles/logosStyles.module.css"

const Swiggy = () => {
    return (
        <svg 
        // swidth="48" height="48" 
        className={styles.svgStyles}
        viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_21_300)">
                <path d="M24.0296 45.9098C24.0011 45.8897 23.9703 45.8624 23.9371 45.8351C23.4082 45.1793 20.1363 41.0809 16.8028 35.8239C15.8019 34.1494 15.1532 32.8425 15.2789 32.5046C15.605 31.6258 21.4171 31.1443 23.2066 31.9413C23.7509 32.1832 23.7367 32.5022 23.7367 32.6896C23.7367 33.4936 23.6975 35.6519 23.6975 35.6519C23.6975 36.0978 24.0592 36.4595 24.5063 36.4583C24.9534 36.4583 25.3139 36.0942 25.3127 35.6472L25.3044 30.2715H25.3032C25.3032 29.8043 24.7933 29.6881 24.6984 29.6691C23.7639 29.6632 21.8677 29.6584 19.8304 29.6584C15.3359 29.6584 14.3302 29.8434 13.5689 29.343C11.9146 28.2615 9.20956 20.9706 9.14671 16.8675C9.05777 11.0803 12.485 6.06996 17.3139 3.5879C19.3299 2.57278 21.6068 2 24.0142 2C31.656 2 37.9507 7.76815 38.7879 15.1906C38.7879 15.1965 38.7879 15.2048 38.7891 15.2108C38.9433 17.005 29.0542 17.3857 27.0987 16.8639C26.7986 16.7844 26.7227 16.4761 26.7227 16.3445C26.7216 14.9807 26.7121 11.1408 26.7121 11.1408C26.7109 10.6937 26.348 10.3332 25.9009 10.3332C25.455 10.3332 25.0933 10.6973 25.0933 11.1444L25.1111 18.217C25.1253 18.6629 25.4989 18.7827 25.6021 18.804C26.7144 18.804 29.3151 18.8028 31.7379 18.8028C35.0121 18.8028 36.3877 19.1823 37.3032 19.8784C37.9116 20.3421 38.1464 21.2303 37.9412 22.3854C36.1043 32.6552 24.4707 45.3809 24.0331 45.911L24.0296 45.9098Z" fill="#FC8019" />
            </g>
            <defs>
                <clipPath id="clip0_21_300">
                    <rect width="30" height="43.911" fill="white" transform="translate(9 2)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Swiggy